
import axios from '@/axios.js'

export default {
  // TASK
    fetchPost ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/post-list', payload)
          .then((response) => {
            commit('SET_POSTS', response.data.data.post)
            commit('SET_TOTALS', response.data.data.total)
            commit('SET_LAST', response.data.data.last_page)
            commit('SET_PER_PAGE', response.data.data.per_page)
            commit('SET_CURRENT_PAGE', response.data.data.current_page)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    addUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/post-add', data)
        .then((response) => {
          commit('ADD_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    editUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/post-add', data)
        .then((response) => {
          commit('EDIT_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    statusUpdate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/status-update', data)
        .then((response) => {
          commit('STATUS_UPDATE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    removePost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/post-remove', data)
        .then((response) => {
          commit('REMOVE_POST', data.ID)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

    restorePost ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/post-restore', data)
        .then((response) => {
          commit('REMOVE_POST', data.ID)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
      })
    },

  // TIPE
    fetchKategori ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/term-list/kategori`)
          .then((response) => {
            commit('SET_KATEGORIS', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    addKategori ({ commit }, tipe) {
      return new Promise((resolve, reject) => {
        axios.post('/term-add', tipe)
          .then((response) => {
            commit('ADD_KATEGORI', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

    fetchAlbum ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/term-list/album`)
          .then((response) => {
            commit('SET_ALBUMS', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    addAlbum ({ commit }, tipe) {
      return new Promise((resolve, reject) => {
        axios.post('/term-add', tipe)
          .then((response) => {
            commit('ADD_ALBUM', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

  // TAG
    fetchTag ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/term-list/tag`)
          .then((response) => {
            commit('SET_TAGS', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },
    addTag ({ commit }, tag) {
      return new Promise((resolve, reject) => {
        axios.post('/term-add', tag)
          .then((response) => {
            commit('ADD_TAG', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

  // STATUS

    fetchStatus ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/term-list/permohonan`)
          .then((response) => {
            commit('SET_KATEGORIS', response.data.data)
            resolve(response.data)
          })
          .catch((error) => { reject(error) })
      })
    },

}
